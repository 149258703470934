<template>
    <div class="header_user_box">
        <div class="user_box">
            <div class="user_name" v-if="$user">{{$user.name}}</div>
            <div class="user_icon_box">
                <div v-if="!$user" :style="'background-image: url(/images/icon/new_icon/user.svg)'" class="user_icon"></div>
                <div v-else>
                    <div v-if="$user.photo_250" :style="'background-image: url('+$user.photo_250+')'" class="user_icon"></div>
                    <img v-else src="/images/logo.png" class="user_icon" alt="icon" />
                </div>
                <img src="/images/icon/arrow.svg" class="arrow" alt="icon">
            </div>
        </div>
        <div class="header_drop_menu">
            <div v-if="!$user" class="header_drop_box">
                <router-link class="menu_item" :to="{name: 'Login', params: { city:$route.params.city, route: $route.fullPath }}">
                    <span>Войти</span>
                </router-link>
                <router-link class="menu_item" :to="{name: 'UserRegistration',params: { city:$route.params.city }}" >
                    <span>Регистрация</span>
                </router-link>
            </div>
            <div v-else class="header_drop_box">
                <router-link :to="{name: 'User'}" class="menu_item">Редактировать</router-link>
                <span class="menu_item" @click="loginOut">Выйти</span>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "User",
    data() {
        return {

        }
    },
    components: {},
    methods: {
        loginOut() {
            this.$user = null;
        },

    }

}
</script>
<style scoped>
.header_user_box {
    position: relative;
    transition: .3s;
    margin-left: 20px;
}

.user_box {
    height: 62px;
    display: flex;
    padding: 5px 0 5px 10px;
    align-items: center;
}

.user_box:hover {
    background: rgba(var(--main-color), .05);
    cursor: pointer;
}

.user_name {
    font-weight: bold;
    padding-right: 10px;
}

.user_icon_box {
    display: flex;
    align-items: center;
    justify-content: center;
}

.user_icon {
    width: 28px;
    height: 28px;
    border-radius: 50%;
    border: none;
    background-color: #fff;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;

}

.arrow {
    margin-left: 2px;
    padding: 6px;
    height: 20px;
    width: 20px;
}

.header_drop_menu {
  display: none;
  position: absolute;
  top: 100%;
  right: 0;
  background-color: #fff;
  z-index: 1;
  transition: 3s;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  border: 1px solid #dce1e6;
}

.header_drop_box {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
}
.header_user_box:hover .header_drop_menu {
  display: block;
}

.menu_item {
    padding: 10px 15px;
    width: 100%;
    text-align: right;
    color: #2c3e50;
    font-size: 14px;
}

.menu_item:not(:last-child) {
    border-bottom: 1px solid rgba(0,0,0, .1);
}

.menu_item:hover {
    background: rgba(var(--main-color), .05);
    cursor: pointer;    
}

.router-link-exact-active {
    background: rgba(var(--main-color), .05);
    color: #2c3e50 !important;
}
</style>