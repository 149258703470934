<!--suppress ALL -->
<template>
    <div id="mobileMenuRight" class="sidenav" v-if="$route.params.city">
        <div class="menu_box">
            <div v-if="$user">
                <div class="mobile_user_box">
                    <div class="mobile_user_icon_box">
                        <div v-if="$user.photo_250" :style="'background-image: url('+$user.photo_250+')'" class="mobile_user_icon"></div>
                        <div v-else src="/images/logo.png" class="mobile_user_icon"></div>
                    </div>
                    <div class="mobile_user_name">
                        <div>{{$user.name}} {{$user.f_name}}</div>
                        <div class="mobile_user_edit">
                            <div class="mobile_edit" style="display:none">Редактировать</div>
                            <div class="mobile_logOut" @click="loginOut">Выйти</div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else>
                <div class="mobile_user_box" v-on:click="menu_show = !menu_show">
                    <div class="mobile_user_icon_box">
                        <div style="background-image: url(/images/icon/user.svg)" class="mobile_user_icon"></div>
                    </div>
                    <div class="mobile_user_name">
                        <div>Вы не авторизованы</div>
                        <div class="mobile_user_edit" v-if="!menu_show">
                            <div class="mobile_edit">Нажмите, чтобы авторизоваться</div>
                        </div>
                        <div class="mobile_user_edit" v-else>
                            <div class="mobile_edit">Нажмите, чтобы свернуть</div>
                        </div>
                    </div>
                </div>
                <transition name="slide-fade">
                    <div class="open_menu" v-if="menu_show">
                        <router-link :to="{name: 'UserRegistration',params: { city:$route.params.city }}" itemprop="url">
                            <div @click="$menu()" class="right_menu_item">
                                <div class="icons advertisement"></div>
                                <span>Регистрация</span>
                            </div>
                        </router-link>
                        <Login @onChange="close_menu" class="login_menu_box"></Login>
                        <div class="login_mobile_container">
                            <div class="login_mobile_title">
                                <span>Или авторизуйтесь через социальные сети</span>
                            </div>
                            <div class="login_mobile_social">
                                <img src="/images/icon/vk.svg" alt="Vk.com" title="вКонтакте" class="icon" @click="login_Vk">
                                <img src="/images/icon/okru.svg" alt="Ok.ru" title="Одноклассники" class="icon" @click="login_OK">
                                <!-- <img src="/images/icon/facebook.svg" alt="Facebook" title="Facebook" class="icon" @click="login_FB"> -->
                            </div>
                        </div>
                    </div>
                </transition>
            </div>
            <div class="mobile_menu_link_box">
                <router-link :to="{name: 'PosterList'}" itemprop="url">
                    <div @click="$menu()" class="right_menu_item">
                        <div class="icons calendar"></div>
                        <span>Афиша</span>
                    </div>
                </router-link>
                <router-link :to="{name: 'AdsCategory'}" itemprop="url">
                    <div @click="$menu()" class="right_menu_item">
                        <div class="icons add"></div>
                        <span>Объявления</span>
                    </div>
                </router-link>
<!--                <router-link :to="{name: 'CommunicationList'}" itemprop="url">-->
<!--                    <div @click="$menu()" class="right_menu_item">-->
<!--                        <div class="icons tape"></div>-->
<!--                        <span>Городская лента</span>-->
<!--                    </div>-->
<!--                </router-link>-->
                <div v-for="(item , index) in menuList" :key="index">
                    <router-link :to="{name: 'InterestingList',params: { id:item.id }}" itemprop="url">
                        <div @click="$menu()" class="right_menu_item">
                            <div class="icons newspaper"></div>
                            <span v-html="$cutText(item.title, 33)"></span>
                        </div>
                    </router-link>
                </div>
                <router-link :to="{name: 'Favorite'}" itemprop="url">
                    <div @click="$menu()" class="right_menu_item">
                        <div class="icons star"></div>
                        <span>Избранное</span>
                    </div>
                </router-link>
                <router-link :to="{name: 'Advertising'}" itemprop="url">
                    <div @click="$menu()" class="right_menu_item">
                        <div class="icons advertisement"></div>
                        <span>Заказать рекламу</span>
                    </div>
                </router-link>
                <router-link :to="{name: 'Help'}" itemprop="url">
                    <div @click="$menu()" class="right_menu_item">
                        <div class="icons question"></div>
                        <span>Помощь</span>
                    </div>
                </router-link>
                <router-link :to="{name: 'Regulation'}" itemprop="url">
                    <div @click="$menu()" class="right_menu_item">
                        <div class="icons documents"></div>
                        <span>Правила</span>
                    </div>
                </router-link>
                <router-link :to="{name: 'Edition'}" itemprop="url">
                    <div @click="$menu()" class="right_menu_item">
                        <div class="icons press-pass"></div>
                        <span>Редакция</span>
                    </div>
                </router-link>
                <router-link :to="{name: 'cityList'}" itemprop="url">
                    <div @click="$menu()" class="right_menu_item">
                        <div class="icons global"></div>
                        <span>Выбор города</span>
                    </div>
                </router-link>
            </div>
        </div>
    </div>
</template>
<script>
import stopList from './stopList.json';
import Login from './../App/User/Form/EmailLogin'
export default {
    name: "MobileMenu",
    components: {
        Login
    },
    data() {
        return {
            menuList: null,
            windowWidth: window.innerWidth,
            direction: 'square',
            menu_show: false,
            stop: null,
        }
    },
    methods: {
        api(name) {
            let url = this.$config.api_url + this.$route.params.city + '/News.getCat';
            this.$http.get(url).then((response) => {
                sessionStorage.setItem(name, JSON.stringify(response.data.response.list));
                this.menuList = response.data.response.list;
            })
        },
        menu() {
            let a = document.getElementById("mobileMenuRight");
            let e = document.querySelector('.menu_box');
            if (this.$leftMenuBurger) {
                a.style.height = '0px';
                e.style.opacity = '0';
            } else {
                a.style.height = '100%';
                setTimeout(function() {
                    e.style.opacity = '1';
                }, 500);

            }
            this.$leftMenuBurger = 1;
        },
        callMenuList() {
            let city = this.$route.params.city;
            let name = city + '_menuList';
            if (city) {
                if (sessionStorage.getItem(name)) {
                    this.menuList = JSON.parse(sessionStorage.getItem(name));
                } else {
                    this.api(name);
                }
            }
        },
        loginOut() {
            this.$user = null;
        },
        login_Vk() {
            // eslint-disable-next-line no-undef
            VK.Auth.login(this.callbackLoginVk, 4194304);

        },
        login_FB() {
            var me = this;
            // eslint-disable-next-line no-undef
            FB.login(function(response) {
                if (response.authResponse) {
                    let form = new FormData();
                    form.set('accessToken', response.authResponse.accessToken);
                    let url = me.$config.api_url + 'User.fbLogin';
                    me.$http.post(url, form).then((response) => {
                        me.$user = response.data;
                        me.$router.push({ name: 'home' });
                    }).catch(() => {
                        me.$user = null;
                    });
                } else {
                    // eslint-disable-next-line no-console
                    console.log('User cancelled login or did not fully authorize.');
                }
            });
        },
        login_OK() {
            window.location.replace("https://connect.ok.ru/oauth/authorize?client_id=1262050560&redirect_uri=" + location.origin + '/login/ok' + "&scope=VALUABLE_ACCESS;LONG_ACCESS_TOKEN&response_type=token", '_blank');
        },
        // eslint-disable-next-line no-unused-vars
        callbackLoginVk(callback) {
            if (callback.status === 'connected') {
                let url = this.$config.api_url + 'Login.vk';
                this.$http.post(url, callback).then((response) => {
                    this.$user = response.data.response;
                    // this.$router.push({ name: 'home' });
                    document.location.href = "/";
                }).catch(() => {
                    this.$user = null;

                })
            } else {
                // eslint-disable-next-line no-console
                console.log('status: ' + callback.status);
            }
        },        
        close_menu() {
            this.menu();
        },
        checkStop() {
            if(this.stop.indexOf(this.$myCity.name_en) != -1) {
                return false;
            } else {
                return true;
            }
        }

    },
    mounted() {
        this.stop = stopList.stopList;
        this.callMenuList();
        //VK
        this.$loadScript('https://vk.com/js/api/openapi.js?168').then(function() {
            // eslint-disable-next-line no-undef
            VK.init({
                apiId: 6323130
            });
        });
        //FB
        /*window.fbAsyncInit = function() {
            // eslint-disable-next-line no-undef
            FB.init({
                appId: '147032306091326',
                autoLogAppEvents: true,
                xfbml: true,
                version: 'v7.0'
            });
        };
        this.$loadScript('https://connect.facebook.net/ru_RU/sdk.js').then(function() {

        });*/
    },
    watch: {
        '$route.params.city': function() {
            this.callMenuList();
        },

    },

}
</script>
<style scoped>
#mobileMenuRight {}

.router-link-exact-active .right_menu_item {
    background: rgba(74, 118, 168, 0.2) !important;
}

.router-link-exact-active .right_menu_item span {
    color: #2c3e50 !important;
}

.router-link-exact-active .right_menu_item .icons {
    background-color: #A9B0C0 !important;
}

.sidenav {
    background: #fff;
    height: 0px;
    width: 100%;
    position: fixed;
    z-index: 1001;
    bottom: 0;
    left: 0;
    overflow-x: hidden;
    transition: 0.5s;
    padding-bottom: 0px;
}

.menu_box {
    opacity: 1;
    transition: .2s;
    background: transparent;
}

.right_menu_item {
    padding: 15px 10px;
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: #2c3e50;
    font-size: 14px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.mobile_menu_link_box a:first-child .right_menu_item {
    border-top: 1px solid rgba(0, 0, 0, 0.1);

}

.icons {
    width: 20px;
    height: 20px;
    background-color: #A9B0C0;
    display: block;
    margin-right: 10px;
}

.mobile_menu a:hover .icons {
    background-color: #fff !important;
}

.home {
    mask-image: url('/images/icon/home.svg');
}

.tape {
    mask-image: url('/images/icon/tape.svg');
}

.news {
    mask-image: url('/images/icon/news.svg');
}

.coronavirus {
    mask-image: url('/images/icon/sickness.svg');
}

.org {
    mask-image: url('/images/icon/org.svg');
}

.shares {
    mask-image: url('/images/icon/supermarket.svg');
}

.add {
    mask-image: url('/images/icon/add.svg');
}

.star {
    mask-image: url('/images/icon/star_menu.svg');
}

.question {
    mask-image: url('/images/icon/question.svg');
}

.advertisement {
    mask-image: url('/images/icon/logotype.svg');
}

.calendar {
    mask-image: url('/images/icon/calendar.svg');
}

.documents {
    mask-image: url('/images/icon/documents.svg');
}

.newspaper {
    mask-image: url('/images/icon/newspaper.svg');
}

.global {
    mask-image: url('/images/icon/global.svg');
}

.astrology {
    mask-image: url('/images/icon/astrology.svg');
}

.play {
    mask-image: url('/images/icon/interface.svg');
}

.press-pass {
    mask-image: url('/images/icon/press-pass.svg');
}

.car {
    mask-image: url('/images/icon/car-insurance.svg');

}

.world {
    mask-image: url('/images/icon/airplane.svg');
    
}

.excursion {
    mask-image: url('/images/icon/excursion.svg');

}

.credit-card {
    mask-image: url('/images/icon/credit-card.svg');

}

.wind {
    mask-image: url('/images/icon/wind.svg');

}

.camera {
    mask-image: url('/images/icon/camera.svg');

}

.mobile_user_box {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 15px;
    text-align: left;
}

.mobile_user_icon_box {
    height: 50px;
    width: 50px;
    display: flex;
    align-items: center;
}

.mobile_user_icon {
    width: 50px;
    height: 50px;
    border-radius: 100%;
    display: inline-block;
    text-align: center;
    text-decoration: none;
    border: none;
    background-color: #fff;
    background-position: 50%;
    background-size: cover;
    font-size: 0;

}

.mobile_user_name {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
}

.mobile_user_edit {
    display: flex;
    flex-direction: row;
}

.mobile_edit {
    font-size: 11px;
    color: #A9B0C0;
}

.mobile_logOut {
    font-size: 11px;
    color: #A9B0C0;
}

.open_menu {
    transition: .5s;
}

.login_menu_box {
    padding: 10px 15px;

}

.slide-fade-enter-active {
    transition: all .8s;
}

.slide-fade-leave-active {
    transition: all .8s;
}

.slide-fade-enter,
.slide-fade-leave-to {
    opacity: 0;
}

.login_mobile_container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 10px;
}

.login_mobile_social {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    padding: 10px;
}

.login_mobile_social img {
    max-height: 40px;
}
</style>