<template>
    <div v-if="$myCity && $myCity.has_partner === false && !$noPartner">
        <noindex>
            <div class="partner_bg_box">
                <div class="partner_card">
                    <div class="partner_card_image_container">
                        <img class="card__image" src="https://images.unsplash.com/photo-1519999482648-25049ddd37b1?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2126&q=80" alt="">
                    </div>
                    <svg class="card__svg" viewBox="0 0 800 500">
                        <path d="M 0 100 Q 50 200 100 250 Q 250 400 350 300 C 400 250 550 150 650 300 Q 750 450 800 400 L 800 500 L 0 500" stroke="transparent" fill="#333" />
                        <path class="card__line" d="M 0 100 Q 50 200 100 250 Q 250 400 350 300 C 400 250 550 150 650 300 Q 750 450 800 400" stroke="pink" stroke-width="3" fill="transparent" />
                    </svg>
                    <div class="card__content">
                        <h1 class="card__title">Этот город не работает</h1>
                        <p>Для запуска, ищем партнёра.<br>
                            Можете стать нашим партнером в этом городе и зарабатывать на рекламе вместе с нами.
                        </p>
                        <div class="partner_button_box">
                            <button type="button" class="partner_button partner_button_close" @click="$noPartner = !$noPartner">Закрыть</button>
                            <router-link :to="{name: 'Partnership'}">
                                <button type="button" class="partner_button partner_button_success" @click="$noPartner = !$noPartner">Подробнее</button>
                            </router-link>
                        </div>
                    </div>
                </div>
                <div class="partner_bg">
                </div>
            </div>
        </noindex>
    </div>
</template>
<script>
export default {
    name: "NoPartner",
    components: {},
    mounted() {}
}
</script>
<style scoped>
img {
    max-width: 100%;
}

.partner_bg_box {
    z-index: 9997;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

}

.partner_bg {
    z-index: 9998;
    background: rgba(0, 0, 0, .6);
    filter: blur(8px);
    position: absolute;
    height: 100%;
    width: 100%;
}

.partner_card {
    position: relative;
    background: #333;
    width: 400px;
    border-radius: 6px;
    padding: 2rem;
    color: #aaa;
    box-shadow: 0 0.25rem 0.25rem rgba(255, 255, 255, 0.2), 0 0 1rem rgba(255, 255, 255, 0.2);
    overflow: hidden;
    z-index: 9999;
}

.partner_card_image_container {
    margin: -2rem -2rem 1rem -2rem;
}

.card__line {
    opacity: 0;
    animation: LineFadeIn 0.8s 0.8s forwards ease-in;
}

.card__image {
    opacity: 0;
    animation: ImageFadeIn 0.8s 1.4s forwards;
}

.card__title {
    color: white;
    margin-top: 0;
    font-weight: 800;
    letter-spacing: 0.01em;
}

.card__content {
    text-align: left;
    padding: 20px 0;
    margin-top: -1rem;
    opacity: 0;
    animation: ContentFadeIn 0.8s 1.6s forwards;
}

.card__content p {
    padding: 20px 0;
}

.card__svg {
    position: absolute;
    left: 0;
    top: 115px;
}

@keyframes LineFadeIn {
    0% {
        opacity: 0;
        d: path("M 0 300 Q 0 300 0 300 Q 0 300 0 300 C 0 300 0 300 0 300 Q 0 300 0 300 ");
        stroke: #fff;
    }

    50% {
        opacity: 1;
        d: path("M 0 300 Q 50 300 100 300 Q 250 300 350 300 C 350 300 500 300 650 300 Q 750 300 800 300");
        stroke: #888bff;
    }

    100% {
        opacity: 1;
        d: path("M -2 100 Q 50 200 100 250 Q 250 400 350 300 C 400 250 550 150 650 300 Q 750 450 802 400");
        stroke: #545581;
    }
}

@keyframes ContentFadeIn {
    0% {
        transform: translateY(-1rem);
        opacity: 0;
    }

    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes ImageFadeIn {
    0% {
        transform: translate(-0.5rem, -0.5rem) scale(1.05);
        opacity: 0;
        filter: blur(2px);
    }

    50% {
        opacity: 1;
        filter: blur(2px);
    }

    100% {
        transform: translateY(0) scale(1);
        opacity: 1;
        filter: blur(0);
    }
}

.partner_button_box {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.partner_button {
    outline: none;
    border: none;
    border-radius: 80px;
    padding: 10px 20px;
    transition: .3s;
}

.partner_button:hover {
    transform: translateY(-5px);
    transition: .2s;
    cursor: pointer;
}

.partner_button_success {
    background: rgba(74, 118, 168, 1);
    color: #fff;
}

.partner_button_close {
    background: #fff;
}
</style>