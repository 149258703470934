<template>
    <div>
        <footer itemscope itemtype="http://schema.org/WPFooter" v-if="$route.params.city">
            <nav itemtype="http://schema.org/SiteNavigationElement">
                <div class="bg_white">
                    <b-container style="padding: 15px 0;">
                        <b-row class="footer_box">
                            <b-col sm="6" lg="4" class="items_box" v-if="$route.params.city">
                                <router-link v-for="(item,index) in dataLeft" :to="{name: item.to}" :key="index" itemprop="url" class="item">
                                    <span v-html="item.name"></span>
                                </router-link>
                            </b-col>
                            <b-col sm="6" lg="4" class="items_box" v-if="$route.params.city">
                                <router-link v-for="(item,index) in dataCenter" :to="{name: item.to}" :key="index" itemprop="url" class="item">
                                    <span v-html="item.name"></span>
                                </router-link>
                            </b-col>
                            <b-col sm="6" lg="4" class="items_box" v-if="$route.params.city">
                                <router-link v-for="(item,index) in dataRight" :to="{name: item.to}" :key="index" itemprop="url" class="item">
                                    <span v-html="item.name"></span>
                                </router-link>
                                <router-link :to="{name: 'Partnership'}" itemprop="url" class="item" target="_blank">
                                    <span>Франшиза "Окей Город"</span>
                                </router-link>
                                <router-link :to="{name: 'Copyright'}" itemprop="url" class="item">
                                    <span>Правила использования материалов</span>
                                </router-link>
                            </b-col>
                            <!-- <b-col sm="6" :lg="$route.params.city ? 3 : 12" class="downloads_box">
                                <span class="download_app_button_title">Скачай бесплатно</span>
                                <div class="download_app_button">
                                    <a href="https://play.google.com/store/apps/details?id=com.goroda.cities" target="_blank" rel="nofollow">
                                        <img src="/images/icon/GooglePlay.svg" alt="GooglePlay" title="GooglePlay" class="icon">
                                    </a>
                                    <a href="https://apps.apple.com/ru/app/id1361334339" target="_blank" rel="nofollow">
                                        <img src="/images/icon/AppStore.svg" alt="AppStore" title="AppStore" class="icon">
                                    </a>
                                    <a href="https://appgallery.huawei.com/#/app/C103438881" target="_blank" rel="nofollow">
                                        <img src="/images/icon/huawei-appgallery.svg" alt="AppGallery" title="AppGallery" class="icon">
                                    </a>
                                </div>
                            </b-col> -->
                        </b-row>
                    </b-container>
                </div>
                <div class="download_box">
                    <b-container style="background: #eee; padding: 15px 0;">
                        <b-row class="footer_box">
                            <b-col sm="6" lg="8" class="qr_container" v-if="$route.params.city">
                                <div class="qr_box">
                                    <div class="qr_item">
                                        <img src="/images/qr.png" alt="" class="img-fluid">
                                    </div>
                                    <div class="qr_description">
                                        <div class="qr_title">Установка мобильного приложения</div>
                                        <div class="qr_desc">Наведите камеру своего телефона на QR-код и перейдите<br>
                                            по ссылке для установки приложения "Окей Город"</div>
                                    </div>
                                </div>
                            </b-col>
                            <b-col :lg="$route.params.city ? 4 : 12" class="downloads_box">
                                <div class="download_app_button">
                                    <a href="https://play.google.com/store/apps/details?id=com.goroda.cities" target="_blank" rel="nofollow" @click="reachYA('download_googlePlay')">
                                        <img src="/images/icon/GooglePlay.svg" alt="GooglePlay" title="GooglePlay" class="icon">
                                    </a>
                                    <a href="https://apps.apple.com/ru/app/id1361334339" target="_blank" rel="nofollow" @click="reachYA('download_ios')">
                                        <img src="/images/icon/AppStore.svg" alt="AppStore" title="AppStore" class="icon">
                                    </a>
                                    <a href="https://appgallery.huawei.com/#/app/C103438881" target="_blank" rel="nofollow" @click="reachYA('download_appgallery')">
                                        <img src="/images/icon/huawei-appgallery.svg" alt="AppGallery" title="AppGallery" class="icon">
                                    </a>
                                </div>
                            </b-col>
                        </b-row>
                    </b-container>
                </div>
                <div class="bg_white">
                    <b-container>
                        <div class="copyrights_box">
                            <router-link :to="{name: 'Home'}" itemprop="url" class="logo_box" title="Главная">
                                <img src="/images/logo_100.png" class="logo" />
                            </router-link>
                            <span>© Все права защищены / All rights reserved</span>
                            <span>Разработано компанией <strong itemprop="copyrightHolder" v-html="`Окей Город`"></strong></span>
                            <span itemprop="copyrightYear" v-html="`2017 - ${$moment().format('YYYY г.')}`" class="leftMenu_footer_date"></span>
                        </div>
                    </b-container>
                </div>
            </nav>
        </footer>
    </div>
</template>
<script>
export default {
    name: "FooterMain",
    components: {},
    data() {
        return {
            dataLeft: [{
                    to: 'NewsList',
                    name: 'Новости',
                    icon: 'news',
                },
                {
                    to: 'PosterList',
                    name: 'Афиша',
                    icon: 'calendar',
                },
                {
                    to: 'SharesList',
                    name: 'Акции',
                    icon: 'shares',
                },
                {
                    to: 'OrganisationCat',
                    name: 'Справочник',
                    icon: 'org',
                },
                {
                    to: 'AdsCategory',
                    name: 'Объявления',
                    icon: 'add',
                },
                // {
                //     to: 'CommunicationList',
                //     name: 'Городская лента',
                //     icon: 'tape',
                // },

            ],
            dataCenter: [
            ],
            dataRight: [{
                    to: 'Help',
                    name: 'Помощь',
                    icon: 'question',
                },
                {
                    to: 'Regulation',
                    name: 'Правила',
                    icon: 'documents',
                },
                {
                    to: 'Edition',
                    name: 'Редакция',
                    icon: 'press-pass',
                },
                {
                    to: 'cityList',
                    name: 'Выбор города',
                    icon: 'global',
                },

            ]
        }
    },
    methods: {
        reachYA(name) {
            this.$metrika.reachGoal(/*{ target: name }*/ name);
        }
    },

}
</script>
<style scoped>


.container {
    padding: 30px 0 70px;
    background: #fff;
}

.router-link-exact-active {
    background: transparent;
}

.router-link-active span {
    color: #666666 !important;
    transition: .2s;
}

.router-link-active span:hover {
    color: #4a76a8 !important;
    transition: .2s;
    cursor: pointer;
}

.items_box {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin-bottom: 20px !important;
}

.item {
    padding: 5px 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 13px;
    color: #666666;
    transition: .2s;
}

.item:hover {
    transition: .2s;
    cursor: pointer;
    color: #4a76a8;
}

.item span {
    display: flex;
    text-align: left;
}

.copyrights_box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    color: #666666;

}

.download_app_button_title {
    font-size: 12px;
    color: #666666;
}

.download_app_button {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
}

.download_app_button img {
    height: 50px;
}

.download_app_button a {
    margin-top: 10px;
}

.copy {
    margin-bottom: 30px;
}

.logo_box {
    margin-bottom: 30px;
    cursor: pointer;
}

.logo {
    height: 100%;
    width: 100%;
}

.download_box {
    background: #edeef0;
}

.qr_box {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.qr_item {
}
.qr_item img {
    max-height: 150px;
}


.qr_description {
    font-family: 'Ubuntu';
    padding: 20px 0 20px 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    height: 100%;
}

.qr_title {
    font-weight: bold;
    text-align: left;
    color: #3b3e44;
    font-size: 20px;
}

.qr_desc {
    padding-top: 20px;
    font-size: 14px;
    text-align: left;
    color: #666666;
}

@media only screen and (max-width: 767px) {
    footer {
        padding-bottom: 50px;
    }

    .row {
        padding: 0;
    }

    .footer_box {
        margin: 0 !important;
        padding: 0 10px !important;
    }

    .download_app_button img {
        height: 50px;
    }

    .qr_container {
        display: none;
    }

}

@media (min-width: 576px) and (max-width: 992px) {

    .qr_container {
        display: none;
    }
}
</style>