<template>
    <b-navbar toggleable="lg" class="topMenu" v-if="$myCity">
        <div class="container">
            <b-navbar-brand v-if="$route.params.city" :to="{name: 'Home'}" class="logo_box">
                <img src="/images/logoHD.svg" alt="" class="logoNew">
                <h1 class="header_title" v-html="$myCity.name" v-if="$route.name === 'Home'"></h1>
                <div class="header_title" v-html="$myCity.name" v-else></div>
            </b-navbar-brand>
            <b-navbar-brand v-else class="logo_box">
                <img src="/images/logoHD.svg" alt="Логотип Окей Город" class="logoNew" />
            </b-navbar-brand>
            <div class="header_right_box">
                <WeatherPlagin v-if="$route.params.city"></WeatherPlagin>
                <b-collapse id="nav-collapse" is-nav v-if="$route.params.city">
                    <div class="header_collapse_box">
                        <div class="header_search_box">
                            <input v-model="Search_input" class="search_input" placeholder="Поиск" @keyup.enter.prevent="redirect" type="text" @keyup="searchTimeOut()">
                            <div class="header_drop_search" v-if="data_search && data_search.length">
                                <div class="header_drop_search_box">
                                    <router-link v-for="(item,index) in data_search" :key="index" :to="{name: search_data[item.type].link, params: {id: item.id}}" itemprop="url">
                                        <div class="header_search_item">
                                            <div class="header_search_item_desc" v-html="search_data[item.type].title"></div>
                                            <div class="header_search_item_title" v-html="$cutText(item.title, 40)"></div>
                                        </div>
                                    </router-link>
                                </div>
                            </div>
                        </div>
                        <User right></User>
                    </div>
                    <!-- <b-navbar-nav class="ml-auto">
                            <input v-if="$route.params.city" v-model="Search_input" class="search_input"  @keyup.enter.prevent="redirect" type="text" @keyup="searchTimeOut()" placeholder="Поиск на портале">

                    </b-navbar-nav> -->
                </b-collapse>
            </div>
        </div>
    </b-navbar>
</template>
<script>
import User from './UserNew';
import WeatherPlagin from "../App/Weather/WeatherPlagin";
export default {
    name: "Etalon",
    components: {
        WeatherPlagin,
        User
    },
    data() {
        return {
            Search_input: null,
            search_data: {
                News: {
                    title: 'Новости',
                    link: 'News'
                },
                Poster: {
                    title: 'Афиша',
                    link: 'Post'
                },
                Organizations: {
                    title: 'Организация',
                    link: 'OrganisationGet'
                },
                Shares: {
                    title: 'Акции',
                    link: 'Shares'
                },
            },
            data_search: []
        }
    },
    methods: {
        api() {
            let url = this.$config.api_url + this.$route.params.city + '/Search.form';
            this.$http.post(url, {
                query: this.Search_input,
                type: 'All',
                limit: 10
            }).then((response) => {
                this.data_search = response.data.response;
                console.log(response.data.response);
            })
        },
        searchTimeOut() {
            if (this.timer) {
                clearTimeout(this.timer);
                this.timer = null;
            }
            this.timer = setTimeout(() => {
                if (this.Search_input && this.Search_input.length >= 3) {
                    this.api();

                }
            }, 500);
        },
        redirect() {
            this.data_search = [];
            this.$router.push({ name: 'Search', params: { search_text: this.Search_input } });
            this.Search_input = null;
        }
    }


}
</script>
<style scoped>
@media only screen and (max-width: 450px) {
    .header_title {
        font-size: 12px !important;
        font-weight: bold !important;
    }

    .topMenu {
        padding: 5px !important;

    }
}

.header_title {
    font-size: 14px !important;
    font-weight: bold !important;
}

.container {
    padding: 0;
    display: flex;
    align-items: center;
}

.topMenu {
    height: 60px;
    padding: 5px 15px;
    background: #fff;
    z-index: 1000;
}

.logo_box {
    display: flex;
    align-items: center;
    color: #2c3e50 !important;
    background: #fff !important;
    font-size: 14px;
}

.logoNew {
    height: 40px;
    border-radius: 6px;
    margin-right: 10px;
}

.header_logo {
    max-height: 35px;
    margin-right: 10px;
}

.header_title {
    margin-right: 10px;
    font-weight: bold;
}

#nav-collapse {
    max-width: 100%;
}

.header_collapse_box {
    flex-shrink: 0;
    display: flex;
    align-items: center;
}

.search_input {
    border: 0;
    margin: 0;
    background-color: #edeef0;
    padding: 5px 10px 5px 30px;
    border-radius: 8px;
    min-width: 250px;
    background-image: url(data:image/svg+xml;charset=utf-8,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cg%20fill%3D%22none%22%20fill-rule%3D%22evenodd%22%20opacity%3D%22.92%22%3E%3Cpath%20opacity%3D%22.1%22%20d%3D%22M0%200H16V16H0z%22%2F%3E%3Cpath%20d%3D%22M6.5%201a5.5%205.5%200%200%201%204.383%208.823l3.896%203.9a.75.75%200%200%201-1.061%201.06l-3.895-3.9A5.5%205.5%200%201%201%206.5%201zm0%201.5a4%204%200%201%200%200%208%204%204%200%200%200%200-8z%22%20fill%3D%22%23818c99%22%20fill-rule%3D%22nonzero%22%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E);
    background-position: 10px;
    background-repeat: no-repeat;
    font-size: 13px;
    outline: 0;
    box-sizing: border-box;
    line-height: 28px;
    transition: background-color .05s, color .05s;
    height: 32px;
    color: #000;
    transition: .5s;
}

.search_input:focus,
.search_input:active {
    background: rgba(74, 118, 168, 0.1);
    background-image: url(data:image/svg+xml;charset=utf-8,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cg%20fill%3D%22none%22%20fill-rule%3D%22evenodd%22%20opacity%3D%22.92%22%3E%3Cpath%20opacity%3D%22.1%22%20d%3D%22M0%200H16V16H0z%22%2F%3E%3Cpath%20d%3D%22M6.5%201a5.5%205.5%200%200%201%204.383%208.823l3.896%203.9a.75.75%200%200%201-1.061%201.06l-3.895-3.9A5.5%205.5%200%201%201%206.5%201zm0%201.5a4%204%200%201%200%200%208%204%204%200%200%200%200-8z%22%20fill%3D%22%23818c99%22%20fill-rule%3D%22nonzero%22%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E);
    background-position: 10px;
    background-repeat: no-repeat;
    transition: .5s;
}

.header_search_box {
    position: relative;
}

.header_drop_search:hover,
.header_search_box .search_input:focus+.header_drop_search,
.header_search_box .search_input:active+.header_drop_search {
    display: block;
}

.header_drop_search {
    display: none;
    position: absolute;
    width: 100%;
    top: 100%;
    right: 0;
    background-color: #fff;
    z-index: 1;
    transition: 3s;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    border: 1px solid #dce1e6;
    max-height: 300px;
    overflow-y: auto;
}

.header_drop_search_box {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
}

.header_drop_search_box a {
    width: 100%;
}



.header_search_item {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 5px;
    text-align: left;
    font-size: 13px;
}

.header_search_item:hover {
    background: rgba(var(--main-color), .05);
    cursor: pointer;

}

.header_drop_search_box a:not(:last-child) .header_search_item {
    border-bottom: 1px solid rgba(0, 0, 0, .1);
}


.header_search_item_title {
    font-weight: bold;
}

.header_search_item_desc {
    color: #626262;
}

.router-link-exact-active {
    background: rgba(var(--main-color), .05);
    color: #2c3e50 !important;
}

.header_right_box {
    display: flex;
}
</style>