<template>
    <div class="weather_box" v-if="this.temperature !== null">
        <router-link :to="{name:'Weather'}" class="link" title="Погода">
            <span class="celsius" style="color: #2c3e50; white-space: nowrap;">{{this.temperature}} °C</span>
        </router-link>
        <a href="https://www.gismeteo.ru/" target="_blank" rel="nofollow"><span class="gismeteo" title="Gismeteo">Gismeteo</span></a>
    </div>
</template>
<script>
export default {
    name: "WeatherPlagin",
    data() {
        return {
            temperature: null,
            icon: null,
        };
    },
    methods: {
        api() {
            let url = this.$config.api_url + this.$route.params.city + '/Weather.get';
            this.$http.get(url).then((response) => {
                this.temperature = response.data.response.temperature.air.C;
                this.icon = response.data.response.icon;
            })
        },
    },
    mounted() {
        this.api();
        setInterval(function() {
            this.api()
        }.bind(this), 600000);
    }

}
</script>
<style scoped>
@media (min-width: 992px) {
    .weather_box {
        margin-right: 20px;
    }

}

.link {
    background-color: transparent;
    text-decoration: none;
    color: #2c3e50 !important;
    font-weight: bold;
    font-size: 14px;
    margin-bottom: -10px;
}

.weather_box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    position: relative;
}

span.gismeteo {
    color: #2c3e50;
    font-size: 8px;
}
</style>