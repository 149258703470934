<!--suppress ALL -->
<template>
    <div>
        <nav v-if="$route.params.city" id="leftMenuNavigation" itemscope itemtype="http://schema.org/SiteNavigationElement">
            <div class="left_menu" v-if="filters && filters.length">
                <div v-for="(item,index) in filters" :key="index" class="left_menu_link_box">
                    <div v-if="item.to === 'AdsCategory' && menuList && menuList.length">
                        <router-link :to="{name: item.to}" itemprop="url">
                            <div class="left_menu_item">
                                <div class="icons" :class="item.icon"></div>
                                <span v-html="item.name"></span>
                            </div>
                        </router-link>
                        <router-link v-for="(menu,key) in menuList" :to="{name: 'InterestingList',params: { id:menu.id }}" :key="key" itemprop="url">
                            <div class="left_menu_item">
                                <div class="icons newspaper"></div>
                                <span :title="menu.title" v-html="cutText(menu.title, 21)"></span>
                            </div>
                        </router-link>
                    </div>
                    <div v-else>
                        <router-link :to="{name: item.to}" itemprop="url">
                            <div class="left_menu_item">
                                <div class="icons" :class="item.icon"></div>
                                <span v-html="item.name"></span>
                            </div>
                        </router-link>
                    </div>
                </div>
            </div>
            <AdsPlaginNew :direction="'left'" class="AdsPlagin" />
            <CBR v-if="checkStop()" />
        </nav>
    </div>
</template>
<script>
import stopList from './stopList.json';
export default {
    name: "LeftMenuNew",
    components: {
    },
    data() {
        return {
            menuList: null,
            data: [{
                    to: 'Home',
                    name: 'Главная',
                    icon: 'home',
                },
                {
                    to: 'NewsList',
                    name: 'Новости',
                    icon: 'news',
                },
                {
                    to: 'PosterList',
                    name: 'Афиша',
                    icon: 'calendar',
                },
                {
                    to: 'SharesList',
                    name: 'Акции',
                    icon: 'shares',
                },
                {
                    to: 'OrganisationCat',
                    name: 'Справочник',
                    icon: 'org',
                },
                {
                    to: 'AdsCategory',
                    name: 'Объявления',
                    icon: 'add',
                },
                // {
                //     to: 'CommunicationList',
                //     name: 'Городская лента',
                //     icon: 'tape',
                // },
                {
                    to: 'Favorite',
                    name: 'Избранное',
                    icon: 'star',
                },
                {
                    to: 'Advertising',
                    name: 'Заказать рекламу',
                    icon: 'advertisement',
                },
                {
                    to: 'cityList',
                    name: 'Выбор города',
                    icon: 'global',
                },

            ],
        }
    },
    methods: {
        api(name) {
            let url = this.$config.api_url + this.$route.params.city + '/News.getCat';
            this.$http.get(url).then((response) => {
                sessionStorage.setItem(name, JSON.stringify(response.data.response.list));
                this.menuList = response.data.response.list;
            })
        },
        callMenuList() {
            let city = this.$route.params.city;
            let name = city + '_menuList';
            if (city) {
                if (sessionStorage.getItem(name)) {
                    this.menuList = JSON.parse(sessionStorage.getItem(name));
                } else {
                    this.api(name);
                }
            }
        },
        cutText(value, limit) {
            return value.length > limit ?
                value.slice(0, limit - 3) + '...' :
                value;
        },
        checkStop() {
            if (this.stop && this.stop.indexOf(this.$myCity.name_en) != -1) {
                return false;
            } else {
                return true;
            }
        }
    },
    mounted() {
        this.stop = stopList.stopList;
        this.callMenuList();
        // Slide.openMenu();
    },
    computed: {
        filters() {
            if (!this.checkStop()) {
                return this.data.filter(elem => {
                    if (!elem.if) {
                        return elem
                    }
                });
            }
            return this.data
        },

    },
    watch: {
        '$route.params.city': function() {
            this.callMenuList();
        },

    },

}
</script>
<style>
#leftMenuNavigation {
    margin-top: 20px;
    position: relative;
    padding-top: 0px;
    width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.left_menu {
    border-radius: 12px;
    background-color: #ffffff !important;
    height: 100%;
    transition: .5s;
    z-index: 1001 !important;
    padding: 0;
    display: flex;
    flex-direction: column;
    text-align: left;
    margin-bottom: 10px;
    padding: 5px;
}


.left_menu span {
    height: 28px;
    line-height: 27px;
    font-size: 12.5px;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0 10px;

}

.router-link-exact-active .left_menu_item {
    background: rgba(74, 118, 168, .1) !important;
}

.router-link-exact-active span {
    color: #282828 !important;
}

.router-link-exact-active .icons {
    background: #A9B0C0;
}

.icons {
    width: 20px;
    height: 20px;
    background-color: #A9B0C0;
    display: block;
}

.home {
    mask-image: url('/images/icon/home.svg');
}

.tape {
    mask-image: url('/images/icon/tape.svg');
}

.coronavirus {
    mask-image: url('/images/icon/sickness.svg');
}

.news {
    mask-image: url('/images/icon/news.svg');
}

.org {
    mask-image: url('/images/icon/org.svg');
}

.shares {
    mask-image: url('/images/icon/supermarket.svg');
}

.add {
    mask-image: url('/images/icon/add.svg');
}

.question {
    mask-image: url('/images/icon/question.svg');
}

.star {
    mask-image: url('/images/icon/star_menu.svg');
}

.advertisement {
    mask-image: url('/images/icon/logotype.svg');
}

.calendar {
    mask-image: url('/images/icon/calendar.svg');
}

.documents {
    mask-image: url('/images/icon/documents.svg');
}

.newspaper {
    mask-image: url('/images/icon/newspaper.svg');
}

.global {
    mask-image: url('/images/icon/global.svg');
}

.astrology {
    mask-image: url('/images/icon/astrology.svg');
}

.play {
    mask-image: url('/images/icon/interface.svg');
}

.press-pass {
    mask-image: url('/images/icon/press-pass.svg');
}

.car {
    mask-image: url('/images/icon/car-insurance.svg');

}

.world {
    mask-image: url('/images/icon/airplane.svg');

}

.excursion {
    mask-image: url('/images/icon/excursion.svg');

}

.credit-card {
    mask-image: url('/images/icon/credit-card.svg');

}

.wind {
    mask-image: url('/images/icon/wind.svg');

}

.camera {
    mask-image: url('/images/icon/camera.svg');

}

.left_menu_item {
    display: flex;
    align-items: center;
    padding: 5px;
    color: #282828;
    white-space: nowrap;
}

.left_menu_item:hover {
    background: rgba(74, 118, 168, 0.1);
}

.left_menu_link_box:first-child a .left_menu_item {
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
}

.left_menu_link_box:last-child a .left_menu_item {
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
}

.franchise_link {
    color: #939393;
    padding-top: 15px;
}
</style>