<template>
    <div id="bottommenu" v-if="$mobileWidth() && $route.params.city">
        <b-navbar toggleable="lg" fixed="bottom" :class="[($route.params.city)?'':'none']">
            <div @click="leftmenu()">
                <router-link :to="{name: 'Home'}" :class="[($route.params.city)?'':'btn disabled']" class="bottom_link d-flex align-items-center">
                    <div class="icons_bottom home_bottom"></div>
                    <span class="d-flex align-items-center">Главная</span>
                </router-link>
            </div>
            <div @click="leftmenu()">
                <router-link :to="{name: 'NewsList'}" :class="[($route.params.city)?'':'btn disabled']" class="bottom_link d-flex align-items-center">
                    <div class="icons_bottom news_bottom"></div>
                    <span class="d-flex align-items-center">Новости</span>
                </router-link>
            </div>
            <div class="bottom_link d-flex justify-content-center" @click="$menu()">
                <div class="icons_bottom menu_bottom"></div>
                <span class="d-flex align-items-center">Меню</span>
            </div>
            <div @click="leftmenu()">
                <router-link :to="{name: 'OrganisationCat'}" :class="[($route.params.city)?'':'btn disabled']" class="bottom_link d-flex align-items-center">
                    <div class="icons_bottom org_bottom"></div>
                    <span class="d-flex align-items-center">Справочник</span>
                </router-link>
            </div>
            <div @click="leftmenu()">
                <router-link :to="{name:'SharesList'}" :class="[($route.params.city)?'':'btn disabled']" class="bottom_link d-flex align-items-center">
                    <div class="icons_bottom shares_bottom"></div>
                    <span class="d-flex align-items-center">Акции</span>
                </router-link>
            </div>
        </b-navbar>
    </div>
</template>
<script>
export default {
    name: "Bottom",
    methods: {
        leftmenu() {
            let a = document.getElementById("mobileMenuRight");
            let e = document.querySelector('.menu_box');
            if (this.$leftMenuBurger) {
                a.style.height = '0px';
                e.style.opacity = '0';
                this.$leftMenuBurger = false;
            }

        },
    }
}
</script>
<style scoped>
.none {
    display: none;
}

@media only screen and (min-width: 768px) {
    #bottommenu {
        display: none;
    }

}

.navbar {
    background: #fff;
    color: #fff;
    height: 52px;
    font-size: 8.5px;
    border-top: 1px solid #eeeeee;
    box-shadow: 0 0 10px rgba(0, 0, 0, .15);
}

.navbar a {
    text-decoration: none;
}

.navbar-text {
    display: inline-block;
    margin-top: 3px;
    padding: 0;
    color: #A9B0C0;
}


.bottom_link {
    display: flex;
    flex-direction: column;
    align-items: center;
}

#bottommenu span {
    color: #4c4c4c;
    padding-top: 2px;
}

#bottommenu a:hover .icons_bottom {
    background-color: #4a76a8 !important;
}

#bottommenu .bottom_link:hover .menu_bottom {
    background-color: #4a76a8 !important;
}

#bottommenu .bottom_link:hover span {
    color: #4a76a8 !important;
}

.router-link-exact-active {
    background: #fff !important;
}

.router-link-exact-active span {
    color: #4a76a8 !important;
}

.icons_bottom {
    width: 25px;
    height: 25px;
    background-color: #4c4c4c;
    display: block;
}

.home_bottom {
    mask-image: url('/images/icon/home.svg');
}

.news_bottom {
    mask-image: url('/images/icon/news.svg');
}

.org_bottom {
    mask-image: url('/images/icon/org.svg');
}

.shares_bottom {
    mask-image: url('/images/icon/supermarket.svg');
}

.menu_bottom {
    mask-image: url('/images/icon/menu.svg');
}
</style>