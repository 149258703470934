<template>
    <div>
        <NoPartner v-if="$myCity && $myCity.has_partner === false" />
        <div v-if="$route.name !== 'Landing' && $route.name !== 'Developer' && $route.name !== 'Partnership'  && $route.name !== 'P_Belorechensk'">
            <HeaderNew v-if="!$noMenu" />
            <!--<div v-if="$route.name !== 'OrganisationGet'">-->
            <div>
                <b-container class="content" style="padding-bottom: 30px;">
                    <b-row>
                        <div v-if="$mobileWidth()">
                            <MobileMenu v-if="!$noMenu" />
                        </div>
                        <div v-else>
                            <LeftMenu v-if="!$noMenu" />
                        </div>
                        <b-col class="con">
                            <main>
                                <router-view />
                            </main>
                        </b-col>
                    </b-row>
                </b-container>
                <FooterMain v-if="!$noMenu" />
                <div class="up_box" @click="Scroll" v-if="$pcWidth()">
                    <div class="up_box_arrow"></div>
                    <div class="up_box_text">Наверх</div>
                </div>
            </div>
            <!--<div v-else>
                <main>
                    <router-view />
                </main>
                <div v-if="$mobileWidth()">
                    <MobileMenu v-if="!$noMenu" />
                </div>
            </div>-->
            <Bottom v-if="!$noMenu" />
        </div>
        <div v-else>
            <main>
                <router-view />
            </main>
        </div>
        <div class="up" @click="Scroll" v-if="!$pcWidth()">Вверх</div>
        <notifications group="error" position="bottom left" />
    </div>
</template>
<script>
import SmartBanner from 'smart-app-banner';
import 'smart-app-banner/dist/smart-app-banner.css';
import HeaderNew from "@/components/Header/Header";
import Bottom from "@/components/Bottom/index";
import LeftMenu from "@/components/Menu/LeftMenuNew";
import MobileMenu from "@/components/Menu/MobileMenuNew";
import NoPartner from "@/components/Plagins/NoPartner";
import FooterMain from "@/components/Footer/FooterMain";
export default {
    name: 'app',
    metaInfo() {
        return {
            titleTemplate: (titleChunk) => {
                return titleChunk ? (titleChunk === '404' ? `${titleChunk}` : `${titleChunk} ${this.$myCity.name} - Окей Город`) : 'Окей Город';
            },
            meta: [
                { rel: 'icon', href: '/favicon.ico' },
            ]
        }
    },
    components: {
        HeaderNew,
        Bottom,
        LeftMenu,
        MobileMenu,
        NoPartner,
        FooterMain,
    },
    data() {
        return {
            location: null,
            test: null
        }
    },
    methods: {
        Scroll() {
            this.$scrollToTop();
        },
        onScroll() {
            if (window.scrollY > 300) {
                if (this.$pcWidth()) {
                    document.querySelector('.up_box').style.display = 'flex';
                } else {
                    document.querySelector('.up').style.display = 'flex';
                }
            } else if (window.scrollY <= 300) {
                if (this.$pcWidth()) {
                    document.querySelector('.up_box').style.display = 'none';
                } else {
                    document.querySelector('.up').style.display = 'none';
                }
            }
        },
        celat() {
            let url = this.$config.api_url + 'City.getList';
            this.$http.get(url).then((response) => {
                this.$celat = response.data.city[this.$myCity.name_en].celat_active;

            })
        },
    },

    created() {
        window.addEventListener('scroll', this.onScroll);
        if (this.$route.query && this.$route.query.noMenu) {
            new SmartBanner({
                daysHidden: 3,
                daysReminder: 3,
                title: 'Приложение твоего города',
                author: 'ООО Города',
                button: 'Скачать',
                store: {
                    ios: 'В App Store',
                    android: 'В Google Play'
                },
                price: {
                    ios: 'Бесплатно',
                    android: 'Бесплатно'
                },
                icon: '/images/lg2.svg'
            })
        }


    },
    destroyed() {
        window.removeEventListener('scroll', this.onScroll);

    },
    mounted() {},
    watch: {
        '$route.params.city': function() {
            if (this.$myCity && this.$myCity.index) {
                this.$celat_list = [];
                this.$ads_count = 0;
                this.$stopCelat = false;

            }
        },
        '$ads_count': async function() {
            if (this.$route.params.city && !this.$stopCelat && this.$celat_list.length <= this.$ads_count) {

                await this.$celatGetList();

            }
        },
    },

}
</script>
<style>
#app {
    font-family: 'Avenir', Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    background: #edeef0;
    min-height: 100vh;
}

@media only screen and (min-width: 768px) {
    .up {
        position: fixed;
        right: 10px;
        bottom: 10px;
    }

    .con {
        padding-right: 0;
    }
}

@media only screen and (max-width: 767px) {
    .con {
        padding: 0;
    }

    .up {
        position: fixed;
        right: 10px;
        bottom: 60px;
    }

}

h1 {
    margin-bottom: 0;
}

.con {
    margin-top: 20px;
}

.radio_mobile_margin {
    margin-top: -20px;
}

.content {}


.btn-success:not(:disabled):not(.disabled):active,
.btn-success:not(:disabled):not(.disabled).active,
.show>.btn-success.dropdown-toggle {
    background: #4a76a8;
    border-color: #4a76a8;
    -webkit-box-shadow: 0 0 0 0.2rem #4a76a8;
    box-shadow: 0 0 0 0.2rem #4a76a8;
}

.btn-success:not(:disabled):not(.disabled):active:focus,
.btn-success:not(:disabled):not(.disabled).active:focus,
.show>.btn-success.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 0.2rem #4a76a8;
    box-shadow: 0 0 0 0.2rem #4a76a8;
}

.up {
    display: none;
    border-radius: 100%;
    height: 60px;
    width: 60px;
    justify-content: center;
    align-items: center;
    background: #4a76a8;
    color: #fff;
    outline: none;
    cursor: pointer;
    z-index: 1000 !important;
}

.up_box {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 200px;
    transition: .3s;
    background: rgba(220, 225, 230, 0);
    cursor: pointer;
    display: flex;
    justify-content: center;
    padding: 20px;
    opacity: 1;
    z-index: 1;
}

.up_box:hover {
    transition: .3s;
    background: rgba(220, 225, 230, 1);

}

.up_box:hover .up_box_arrow {
    border-color: #45688e;
    transition: .3s;
}

.up_box:hover .up_box_text {
    color: #45688e;
    transition: .3s;
}

.up_box_arrow {
    height: 12.5px;
    width: 12.5px;
    transform: rotate(135deg) translateY(-50%);
    border-style: solid;
    border-width: 0 0 2px 2px;
    border-color: #b8c3ce;
    margin-right: 20px;
    transition: .3s;

}

.up_box_text {
    height: 15px;
    color: #b8c3ce;
    font-size: 12.5px;
    font-weight: 700;
    transition: .3s;
}

.content {
    max-width: 1140px;
}

@media (min-width: 768px) and (max-width: 1140px) {
    .content > .row {
        padding: 0 15px;
    }

}

</style>